<template>
  <v-dialog
    v-model="dialog"
    content-class="modal-window-container"
    @input="onDialogInput"
  >
    <template #activator="{ on }">
      <v-btn
        v-t="`support.${camelize(buttonName)}`"
        depressed
        outlined
        rounded
        color="primary"
        data-cy="support-ticket-button"
        v-on="on"
      />
    </template>
    <v-card>
      <v-card-title data-cy="modal-support">
        <v-row
          justify="space-between"
          align="center"
          no-gutters
        >
          <v-col
            cols="9"
            sm="auto"
          >
            <span
              v-t="'support.createNewSupportTicket'"
              class="headline"
            />
          </v-col>
          <v-col
            cols="auto"
          >
            <v-icon
              data-cy="close-modal-support"
              @click="closeDialog"
            >
              {{ closeIcon }}
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <material-support-new-ticket-form
          v-if="dialog && !newTicketSubmitted"
        />
        <core-element-created-message v-else />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { CLOSE_ICON } from '../../../../constants/app'
export default {
  props: {
    buttonName: {
      type: String,
      default: 'Create new support ticket'
    }
  },
  data () {
    return {
      dialog: false,
      closeIcon: CLOSE_ICON
    }
  },
  computed: {
    ...mapState('support', ['newTicketSubmitted'])
  },
  methods: {
    ...mapMutations('support', ['setNewTicketSubmitted']),
    ...mapMutations('support', ['setErrors']),
    closeDialog () {
      this.dialog = false
      this.onDialogInput()
    },
    onDialogInput () {
      this.setErrors({})
      if (!this.dialog) {
        this.setNewTicketSubmitted(false)
      }
    },
    camelize (string) {
      return this.$options.filters.camelize(string)
    }
  }
}
</script>
